 // email.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailData } from './mailmodel';
 

@Injectable({
  providedIn: 'root',
})
export class BrevomailService {
  private brevoApiUrl = 'https://api.brevo.com'; 
  private apiKey = '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'; // Replace with your actual API key

  constructor(private http: HttpClient) {}

  TendEmailj(emailData: EmailData): Observable<any> {
    debugger;
    const url = `${this.brevoApiUrl}/v3/smtp/email`;
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'api-key': this.apiKey,
      'Content-Type': 'application/json',
    });

    return this.http.post(url, emailData, { headers });
  }
}
 



