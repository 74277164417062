import { Component, OnInit } from '@angular/core';
import { BrevomailService } from '../brevomail.service';
import { EmailData } from '../mailmodel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  myForm: FormGroup;
  msj:any;
  constructor(private formBuilder: FormBuilder, private mailinterface:BrevomailService) { }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
 
  }
  sendTransactionalEmail() {
    if (this.myForm.valid) {
      const formData = this.myForm.value;
    const emailData: EmailData = {
      sender: { email: 'mbasudev@outlook.com' },
      to: [{ email: 'mbasudev12@gmail.com' }],
      replyTo: { email: formData.email },
      textContent: formData.message,
      subject: formData.name,
      tags: ['Index4 Support'],
    };

    this.mailinterface.TendEmailj(emailData).subscribe(response => {
        console.log('Email sent successfully:', response);
        this.msj='Thank you!'
      },
      (error) => {
        console.error('Error sending email:', error);
      });
      this.myForm.reset();
    }else{
this.msj='Please fill in all required fields with valid data.';
    }

 
 
 
 

}
}
